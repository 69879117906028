import {Injectable} from '@angular/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {AuthService} from './auth.service';
import {Platform} from '@ionic/angular';
import {firstValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class FileDownloadService {


  constructor(private authService: AuthService,
              private platform: Platform,
              private http: HttpClient,) {
  }

  public async downLoadFileToLibrary(url: string, filename: string): Promise<Blob | string> {

    if (this.platform.is('mobile')) {
      try {
        const session = this.authService.getCurrentUserSession();
        const downloadedFile = await Filesystem.downloadFile({
          url: url,
          headers: {'Authorization': 'Bearer ' + session.token},
          path: filename,
          directory: Directory.Library,
          method: 'GET',
        });
        return this.getFile(downloadedFile.path);
      } catch (error) {
        return null;
      }
    } else {
      return await firstValueFrom(this.http.get(url, {responseType: 'blob'}));
    }

  }

  public async getFile(filename: string): Promise<Blob | string> {
    if (this.platform.is('mobile')) {
      try {
        const file = await Filesystem.readFile({
          path: filename,
          directory: Directory.Library,
        })
        return file.data;
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }

  }

}
