import {
  AscentDoc,
  AscentType,
  BoulderDoc,
  BoulderListDoc,
  CommentDoc,
  DifficultyRatingDoc,
  FootholdConfig,
  GymChatMessageDoc,
  GymInfoDoc,
  Hold,
  LocalGymReferenceDoc,
  QualityRatingDoc,
  ReactionDoc,
  WallDoc,
  WallImageDoc
} from './docs';
import { CommonUtils } from './index';
import { GymDBDocType } from './doc-types';

export class DocsCreator {

  public static DOC_SEPARATOR = '+';

  public static createWallImage(
    wallId: string,
    userName: string,
    currentness: string,
    timeUploaded: string,
    imageId: string): WallImageDoc {
    const type = GymDBDocType.WALL_IMAGE;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      wallId: wallId,
      imageId: imageId,
      userName: userName,
      currentness: currentness,
      timeUploaded: timeUploaded,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createWall(wallName: string, username: string): WallDoc {
    const type = GymDBDocType.WALL;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      wallName: wallName,
      author: username,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createBoulder(
    boulderName: string,
    wallId: string,
    wallImageId,
    author: string,
    holds: Hold[],
    footholdConfig: FootholdConfig,
    isPrivate: boolean,
    enumerateHolds: boolean
  ): BoulderDoc {
    const type = GymDBDocType.BOULDER;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      boulderName: boulderName,
      wallId: wallId,
      wallImageId: wallImageId,
      author: author,
      holds: holds,
      timeCreated: new Date().toISOString(),
      footholdConfig: footholdConfig,
      isPrivate: isPrivate,
      enumerateHolds: enumerateHolds,
      deprecated: false
    };
  }

  public static createComment(
    boulderId: string,
    comment: string,
    author: string
  ): CommentDoc {
    const type = GymDBDocType.COMMENT;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      boulderId: boulderId,
      comment: comment,
      author: author,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createGymChatMessage(
    message: string,
    author: string
  ): GymChatMessageDoc {
    const type = GymDBDocType.GYM_CHAT_MESSAGE;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      message: message,
      author: author,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createBoulderList(
    name: string,
    author: string,
    publicList: boolean,
    isProjectList = false
  ): BoulderListDoc {
    const type = GymDBDocType.BOULDER_LIST;
    return {
      _rev: undefined,
      type: type,
      _id: isProjectList ? this.getProjectListId(author) : this.createCouchId(type),
      name: name,
      author: author,
      public: publicList,
      boulderIds: [],
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createAscent(
    boulderId: string,
    author: string,
    ascentType: AscentType,
  ): AscentDoc {
    const type = GymDBDocType.ASCENT;
    return {
      _rev: undefined,
      type: type,
      _id: this.createIdBasedOnDocId(boulderId, author, type),
      boulderId: boulderId,
      author: author,
      ascentType: ascentType,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createDifficultyRating(
    boulderId: string,
    difficulty: number,
    author: string
  ): DifficultyRatingDoc {
    const type = GymDBDocType.DIFFICULTY_RATING;
    return {
      _rev: undefined,
      type: type,
      _id: this.createIdBasedOnDocId(boulderId, author, type),
      boulderId: boulderId,
      difficulty: difficulty,
      author: author,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createReaction(
    docId: string,
    author: string,
    emoji: string
  ): ReactionDoc {
    const type = GymDBDocType.REACTION;
    return {
      _rev: undefined,
      type: type,
      _id: this.createIdBasedOnDocId(docId, author, type),
      docId: docId,
      author: author,
      emoji: emoji,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createQualityRating(
    boulderId: string,
    quality: number,
    author: string
  ): QualityRatingDoc {
    const type = GymDBDocType.QUALITY_RATING;
    return {
      _rev: undefined,
      type: type,
      _id: this.createIdBasedOnDocId(boulderId, author, type),
      boulderId: boulderId,
      quality: quality,
      author: author,
      timeCreated: this.now(),
      deprecated: false
    };
  }

  public static createGymInfo(
    gymName: string,
    city: string,
    country: string,
    address: string,
    website: string,
    gymAdmin: string): GymInfoDoc {
    const type = GymDBDocType.GYM_INFO;
    return {
      _rev: undefined,
      type: type,
      _id: this.createCouchId(type),
      gymName: gymName,
      city: city,
      country: country,
      address: address,
      website: website,
      gymAdmin: gymAdmin,
      timeCreated: this.now(),
      isPrivate: false,
      gymRules: '',
      gymAdmins: [],
      deprecated: false
    };
  }

  public static createLocalGymReference(
    username: string,
    gymId: string
  ): LocalGymReferenceDoc {
    return {
      _id: DocsCreator.createLocalGymReferenceId(username, gymId),
      _rev: undefined,
      type: 'gym_reference',
      gymDBName: CommonUtils.getDBNameForGymId(gymId),
      username: username
    };
  }

  public static createLocalGymReferenceId(username, gymId) {
    return username + DocsCreator.DOC_SEPARATOR + CommonUtils.getDBNameForGymId(gymId);
  }

  public static createCouchId(type: GymDBDocType) {
    return this.getCouchIdPrefix(type) + this.createUUID();
  }

  public static getProjectListId(author: string) {
    return this.getCouchIdPrefix(GymDBDocType.BOULDER_LIST) + 'projects' + author;
  }

  public static isProjectBoulderListId(id: string, author: string) {
    return id === this.getProjectListId(author);
  }

  /**
   * For 1-1 relationships between a boulder doc and another doc, e.g. difficulty rating or ascent,
   * we create the id based on the uuid contained in the boulder id.
   * This way we prevent to have multiple documents pointing to one boulder. No cheater will be able to rate a boulder twice :-)
   */
  public static createIdBasedOnDocId(docId: string, author: string, gymDocType: GymDBDocType) {
    return this.getCouchIdPrefix(gymDocType) + DocsCreator.extractUUID(docId) + author + gymDocType;
  }

  public static getCouchIdPrefix(type: GymDBDocType) {
    return type + this.DOC_SEPARATOR;
  }

  public static createUUID(): string {
    const length = 30;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; ++i) {
      result += charset[Math.floor(Math.random() * charset.length)];
    }
    return result;
  }

  public static now(): string {
    return new Date().toISOString();
  }

  private static extractUUID(docId) {
    const parts = docId.split(this.DOC_SEPARATOR);
    if (parts.length !== 2) {
      throw new Error('Invalid doc id: ' + docId);
    }
    return parts[1];
  }
}
