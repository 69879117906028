import { File } from '@awesome-cordova-plugins/file/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonicRouteStrategy, ModalController, Platform } from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppStoreService } from 'src/services/app-store.service';
import { FileDownloadService } from 'src/services/file-download.service';
import { GradeService } from 'src/services/grade.service';
import { HoldDetectionService } from 'src/services/hold-detection.service';
import { HoldsResolverService } from 'src/services/holds-resolver.service';
import { UserService } from 'src/services/user.service';
import { WallImageComponentService } from '../components/wall-image/wall-image.component.service';
import { BBErrorHandler } from '../errors/bb-error-handler';
import { ActiveGymService } from '../services/active-gym.service';
import { AscentService } from '../services/ascent.service';
import { AuthHttpInterceptor } from '../services/auth-http-interceptor';
import { AuthService } from '../services/auth.service';
import { BBLoginService } from '../services/bblogin.service';
import { BoulderListService } from '../services/boulder-list.service';
import { BoulderService } from '../services/boulder.service';
import { CachedBoulderService } from '../services/cached.boulder.service';
import { CommentService } from '../services/comment.service';
import { CouchService } from '../services/couch.service';
import { DbChangesService } from '../services/db.changes.service';
import { DeepLinksService } from '../services/deep-links.service';
import { DifficultyRatingService } from '../services/difficulty-rating.service';
import { DocQueryService } from '../services/doc.query.service';
import { DocWriteService } from '../services/doc.write.service';
import { ErrorNotificationService } from '../services/error-notification.service';
import { FeedItemService } from '../services/feed-item.service';
import { GuestService } from '../services/guest-service';
import { GymChatService } from '../services/gym-chat.service';
import { GymService } from '../services/gym.service';
import { ImageCachingService } from '../services/image-caching.service';
import { ImageUploadService } from '../services/image-upload.service';
import { ImageService } from '../services/image.service';
import { LoadingService } from '../services/loading.service';
import { Mp3Service } from '../services/mp3.service';
import { PouchDBService } from '../services/pouch-db.service';
import { PouchDocService } from '../services/pouch-doc.service';
import { PouchBoulderService } from '../services/pouch.boulder.service';
import { PouchGymService } from '../services/pouch.gym.service';
import { QualityRatingService } from '../services/quality-rating.service';
import { RankingService } from '../services/ranking.service';
import { ReactionService } from '../services/reaction.service';
import { RestService } from '../services/rest.service';
import { SchemaService } from '../services/schema.service';
import { TimerService } from '../services/timer.service';
import { TranslationService } from '../services/translation.service';
import { ValidationService } from '../services/validation.service';
import { VersionService } from '../services/version.service';
import { WallService } from '../services/wall.service';
import { MatomoTrackerMock, ModalControllerMock } from '../test/mocks-ionic';
import { BBStorage } from '../utils/bb-storage';
import { AppInitializer } from './app-initializer';

@Injectable()
export class BBHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  };
}

export const providers = [
  // boulderbook
  AscentService,
  AuthService,
  PouchDocService,
  DbChangesService,
  PouchBoulderService,
  { provide: BoulderService, useClass: CachedBoulderService },
  { provide: DocWriteService, useClass: ActiveGymService },
  { provide: DocQueryService, useClass: ActiveGymService },
  { provide: GymService, useClass: PouchGymService },
  CommentService,
  BoulderListService,
  DifficultyRatingService,
  AppInitializer,
  BBLoginService,
  BBStorage,
  CouchService,
  ErrorNotificationService,
  ImageCachingService,
  ImageService,
  ImageUploadService,
  PouchDBService,
  QualityRatingService,
  RestService,
  SchemaService,
  ValidationService,
  WallService,
  RankingService,
  VersionService,
  WallImageComponentService,
  FeedItemService,
  DeepLinksService,
  TranslationService,
  GymChatService,
  GuestService,
  LoadingService,
  TimerService,
  Mp3Service,
  GradeService,
  HoldDetectionService,
  FileDownloadService,
  HoldsResolverService,
  ReactionService,
  AppStoreService,
  UserService,

  // ionic/angular
  Media,
  Insomnia,
  File,
  HttpClient,
  ScreenOrientation,
  SocialSharing,
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  { provide: ErrorHandler, useClass: BBErrorHandler },
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  { provide: HAMMER_GESTURE_CONFIG, useClass: BBHammerConfig },
  { provide: Sentry.TraceService, deps: [Router] }
];

export const providersForTest = [
  ...providers,
  { provide: ModalController, useClass: ModalControllerMock },
  { provide: MatomoTracker, useClass: MatomoTrackerMock },
  Platform
];
