import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NewAscent } from '../components/add-ascent/AddAscentComponent';
import { Boulder } from '../model/boulder';
import { RankingEntry } from '../model/ranking-entry';
import { AscentService } from './ascent.service';
import { BoulderService } from './boulder.service';

export const MAX_BOULDERS_USED_FOR_SCORE = 10;
export const BONUS_FOR_FLASH = 1;
const MAX_ASCENTS_USED_FOR_SCORE = 10;

@Injectable()
export class RankingService {

  constructor(
    private ascentService: AscentService,
    private boulderService: BoulderService) {
  }

  private static sortAndSetRank(entries: RankingEntry[]) {
    entries.sort((a: RankingEntry, b: RankingEntry) => {
      return a.score == b.score ? a.user.localeCompare(b.user) : a.score < b.score ? 1 : -1;
    });
    let currentRank = 1;
    for (let rank = 0; rank < entries.length; rank++) {
      const previousRanking = rank > 0 ? entries[rank - 1] : null;
      const currentRanking = entries[rank];
      if (previousRanking?.score === currentRanking.score) {
        currentRanking.rank = previousRanking.rank;
      } else {
        currentRanking.rank = currentRank;
      }
      currentRank++;
    }
  }

  public async rankingPointsReceivedForSend(username: string, boulderId: string, newAscent: NewAscent): Promise<number> {
    const boulder = await firstValueFrom(this.boulderService.getBoulderDetails$(boulderId));
    const pointsForSend = newAscent.isFlash ? boulder.grade + BONUS_FOR_FLASH : boulder.grade;
    const ascentsForUser = await firstValueFrom(this.ascentService.getMyAscentsForUser$(false, username));
    const sortedAscentsWithoutThisBoulder = ascentsForUser.filter(a => a.boulderId !== boulderId).sort((a, b) => a.score < b.score ? 1 : -1);
    if (sortedAscentsWithoutThisBoulder.length > 0) {
      let leastScoreOfABoulderInRanking: number;
      if (sortedAscentsWithoutThisBoulder.length > MAX_ASCENTS_USED_FOR_SCORE) {
        leastScoreOfABoulderInRanking = sortedAscentsWithoutThisBoulder[MAX_BOULDERS_USED_FOR_SCORE - 1].score;
      } else {
        leastScoreOfABoulderInRanking = sortedAscentsWithoutThisBoulder[sortedAscentsWithoutThisBoulder.length - 1].score;
      }
      return Math.max(0, pointsForSend - leastScoreOfABoulderInRanking);
    } else {
      return pointsForSend;
    }
  }

  public async getCreatorRanking(allTime: boolean): Promise<RankingEntry[]> {
    const boulders = (await firstValueFrom(allTime ? this.boulderService.getAllTimeBoulders$() : this.boulderService.getBoulders$()));
    return this.calculateCreatorRating(boulders);
  }

  public async getBoulderRanking(allTime: boolean): Promise<RankingEntry[]> {
    const boulders = (await firstValueFrom(allTime ? this.boulderService.getAllTimeBoulders$() : this.boulderService.getBoulders$()));
    const ascents = boulders.map(b => b.ascents.map(ascentListEntry => {
      return new Ascent(ascentListEntry.id, ascentListEntry.userName, b.grade, ascentListEntry.flash);
    })).reduce((prev, current) => [...prev, ...current], []);
    return this.calculateBoulderRating(ascents);
  }

  public calculateBouldererRating(boulders: Boulder[]): RankingEntry[] {
    const ascents = boulders.map(b => b.ascents.map(ascentListEntry => {
      return new Ascent(ascentListEntry.id, ascentListEntry.userName, b.grade, ascentListEntry.flash);
    })).reduce((prev, current) => [...prev, ...current], []);
    return this.calculateBoulderRating(ascents);
  }

  public calculateCreatorRating(boulders: Boulder[]): RankingEntry[] {
    const bouldersByAuthor = this.groupBy(boulders, 'author');
    const entries = [];
    for (const author in bouldersByAuthor) {
      const bouldersForAuthor = bouldersByAuthor[author];
      bouldersForAuthor.sort((a: Boulder, b: Boulder) => {
        return a.rating == b.rating ? a.author.localeCompare(b.author) : a.rating < b.rating ? 1 : -1;
      });
      let score = 0;
      bouldersForAuthor.forEach(function (boulder, index) {
        if (index < MAX_BOULDERS_USED_FOR_SCORE) {
          score += boulder.rating;
        }
      });
      entries.push(new RankingEntry(author, -1, Math.round(score)));
    }
    RankingService.sortAndSetRank(entries);
    return entries;
  }

  private groupBy(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item,
        ],
      }),
      {},
    );
  }


  private calculateBoulderRating(ascents: Ascent[]): RankingEntry[] {
    const ascentsByUser = this.groupBy(ascents, 'username');
    const entries = [];
    for (const username in ascentsByUser) {
      const ascentsForUser = ascentsByUser[username];
      ascentsForUser.sort((a: Ascent, b: Ascent) => {
        return a.getScore() == b.getScore() ? 0 : a.getScore() > b.getScore() ? -1 : 1;
      });
      const score = ascentsForUser.slice(0, MAX_ASCENTS_USED_FOR_SCORE).map(a => a.getScore()).reduce((a, b) => a + b);
      entries.push(new RankingEntry(username, -1, score));
    }
    RankingService.sortAndSetRank(entries);
    return entries;
  }
}

class Ascent {
  constructor(
    readonly ascentId: string,
    readonly username: string,
    readonly grade: number,
    readonly flash: boolean
  ) {
  }

  getScore(): number {
    return getScore(this.grade, this.flash);
  }
}

export const getScore = (grade: number, flash: boolean) => flash ? grade + BONUS_FOR_FLASH : grade;
