import { Component, OnInit } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MatomoTracker } from 'ngx-matomo-client';
import { firstValueFrom } from 'rxjs';
import { ActiveGymFacade } from 'src/store/active-gym/active-gym.facade';
import { AddAscentComponent, NewAscent } from '../../components/add-ascent/AddAscentComponent';
import { GuestInfoComponent } from '../../components/guest-info/guest-info.component';
import { Boulder } from '../../model/boulder';
import { AscentService } from '../../services/ascent.service';
import { AuthService } from '../../services/auth.service';
import { BoulderListService } from '../../services/boulder-list.service';
import { BoulderService } from '../../services/boulder.service';
import { shareBoulderLink } from '../../services/deep-links.service';
import { DifficultyRatingService } from '../../services/difficulty-rating.service';
import { GuestService } from '../../services/guest-service';
import { QualityRatingService } from '../../services/quality-rating.service';
import { AddToBoulderListPage } from '../add-to-boulder-list/add-to-boulder-list.page';
import { CreateBoulderPage } from '../create-boulder/create-boulder.page';
import { GradeListPage } from '../grade-list/grade-list.page';

@Component({
  selector: 'app-boulder-details',
  templateUrl: './boulder-details.page.html',
  styleUrls: ['./boulder-details.page.scss'],
})
export class BoulderDetailsPage implements OnInit {

  activeSegment = 'image';
  boulderId: string;
  boulderIds: string[];
  boulder: Boulder;
  imageFullscreenMode: Boolean = false;
  private boulderChanged = false;
  private wasTicked = false;
  isAllowedToEdit = false;
  loading = false;

  constructor(
    private platform: Platform,
    private logger: NGXLogger,
    private matomoTracker: MatomoTracker,
    private socialSharing: SocialSharing,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private ascentService: AscentService,
    private qualityRatingService: QualityRatingService,
    private difficultyRatingService: DifficultyRatingService,
    private boulderService: BoulderService,
    private boulderListService: BoulderListService,
    private guestService: GuestService,
    private translate: TranslateService,
    private activeGymFacade: ActiveGymFacade
    ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.initializePage();
  }

  private async initializePage() {
    if (!this.boulderId && !this.boulder) {
      return;
    }
    if (this.boulder && this.boulderId != this.boulder.boulderId) {
      this.boulder = null;
    }
    if (!this.boulder) {
      await this.reloadBoulderDetails();
    }
    this.checkIfAllowedToEdit();
    this.matomoTracker.trackPageView('boulder-details');
  }

  async sentIt() {
    if (this.guestService.isGuest()) {
      GuestInfoComponent.showAsModal(this.modalCtrl);
      return;
    }
    const modal = await this.modalCtrl.create({
      component: AddAscentComponent,
      componentProps: {
        currentAverageGrade: this.boulder.grade,
        isOwnBoulder: this.boulder.author === this.authService.getCurrentUsername()
      }
    });
    modal.onDidDismiss().then(async data => {
      if (data && data.data) {
        this.loading = true;
        try {
          const newAscent: NewAscent = data.data;
          await this.storeAscent(newAscent);
          this.boulderChanged = true;
          this.wasTicked = true;
          await this.reloadBoulderDetails();
        } finally {
          this.loading = false;
        }
      }
    });
    await modal.present();
  }


  async close() {
    await this.modalCtrl.dismiss({
      isDirty: this.boulderChanged,
      wasTicked: this.wasTicked
    });
  }


  async editBoulder() {
    if (!this.isAllowedToEdit) {
      return;
    }
    const numAscents = this.boulder.ascents.filter(a => a.userName !== this.boulder.author).length;
    if (numAscents > 0) {
      const alert = await this.alertController.create({
        header: this.translate.instant('boulder-details-page.cannot-be-deleted'),
        buttons: [
          {
            text: 'Ok',
            role: 'ok'
          }
        ]
      });
      await alert.present();
    } else {
      // we need to change segment in order to hide the image-component as this component
      // cannot be visible in multiple components at the same time
      const previousSegment = this.activeSegment;
      this.activeSegment = 'edit';
      const modal = await this.modalCtrl.create({
        id: 'create-boulder-modal',
        component: CreateBoulderPage,
        componentProps: { boulderToEdit: this.boulder }
      });
      modal.onDidDismiss().then((data) => {
        this.activeSegment = previousSegment;
        if (data?.data?.changed) {
          this.boulderChanged = true;
          this.ionViewDidEnter();
        }
      });
      return await modal.present();
    }
  }

  public async removeAscent() {
    await this.doWhileLoading(async () => {
      await this.ascentService.setNoAscent(this.boulderId);
      if (this.authService.getCurrentUsername() !== this.boulder.author) {
        await this.difficultyRatingService.removeRating(this.boulderId);
      }
      await this.qualityRatingService.removeRating(this.boulderId);
      this.boulder = await firstValueFrom(this.boulderService.getBoulderDetails$(this.boulderId));
    });
    this.boulderWasChanged();
  }

  private async doWhileLoading(methodToExecute) {
    this.loading = true;
    try {
      await methodToExecute();
    } finally {
      this.loading = false;
    }
  }

  private async reloadBoulderDetails() {
    await this.doWhileLoading(async () => {
      this.boulder = null;
      this.boulder = await firstValueFrom(this.boulderService.getBoulderDetails$(this.boulderId));
      if (!this.boulder) {
        const allTimeBoulders = await firstValueFrom(this.boulderService.getAllTimeBoulders$());
        this.boulder = allTimeBoulders.filter(b => b.boulderId === this.boulderId)[0];
      }
    });
  }

  async toggleImageFullscreenMode() {
    this.imageFullscreenMode = true;
  }

  private boulderWasChanged() {
    this.boulderChanged = true;
  }

  async gradeClicked() {
    await this.showGradeModal(false);
  }

  async showGradeModal(showGradeModalOnInit: boolean) {
    const modal = await this.modalCtrl.create({
      component: GradeListPage,
      componentProps: {
        boulderId: this.boulderId,
        ascents: this.boulder.ascents,
        showGradeModalOnInit: showGradeModalOnInit
      }
    }
    );
    modal.onDidDismiss().then(data => {
      if (data && data.data) {
        this.boulderWasChanged();
        this.reloadBoulderDetails();
      }
    });
    await modal.present();
  }

  private checkIfAllowedToEdit() {
    this.isAllowedToEdit = this.authService.isAdmin() || this.boulder?.author === this.authService.getCurrentUsername();
  }

  public getAscentForUser() {
    if (this.loading) {
      return null;
    }
    return this.boulder?.ascents.filter(a => a.userName === this.authService.getCurrentUsername())[0];
  }

  async nextBoulder() {
    const index = this.boulderIds.indexOf(this.boulderId);
    if (index > -1 && index < this.boulderIds.length - 1) {
      this.boulderId = this.boulderIds[index + 1];
      this.boulder = null;
      await this.initializePage();
    }
  }

  async previousBoulder() {
    const index = this.boulderIds.indexOf(this.boulderId);
    if (index > 0) {
      this.boulderId = this.boulderIds[index - 1];
      this.boulder = null;
      await this.initializePage();
    }
  }

  async share() {
    const gymId = await firstValueFrom(this.activeGymFacade.activeGymId$);
    const link = shareBoulderLink(gymId, this.boulderId);
    this.logger.log('share link ', link);
    if (this.platform.is('mobile')) {
      this.socialSharing.share(null, null, null, link);
    } else {
      navigator['clipboard'].writeText(link);
    }
  }

  async addToProjects() {
    (this.boulder as any).isProject = true;
    this.boulderListService.addBoulderIdToProjectList(this.boulderId);
  }

  async addToList() {
    const modal = await this.modalCtrl.create({
      component: AddToBoulderListPage,
      componentProps: { boulderId: this.boulderId }
    }
    );
    modal.onDidDismiss().then(data => {
      if (data && data.data) {
        this.boulderWasChanged();
      }
    });
    return await modal.present();
  }

  public async gradeSelected($event: any) {
    this.logger.info('gradeSelected ', $event?.detail);
    if ($event?.detail?.value && this.getAscentForUser()) {
      const selectedGrade = parseInt($event?.detail?.value);
      await this.doWhileLoading(async () => {
        await this.difficultyRatingService.setPersonalRating(this.boulderId, selectedGrade);
        await this.boulderWasChanged();
        this.close();
      });
    }
  }


  private async storeAscent(newAscent: NewAscent) {
    if (newAscent.personalGrade) {
      await this.difficultyRatingService.setPersonalRating(this.boulderId, newAscent.personalGrade);
    }
    await this.ascentService.createOrUpdateAscentType(this.boulderId, newAscent.isFlash ? 'flash' : 'redpoint');
    if (newAscent.thumb === 'thumb-up') {
      await this.qualityRatingService.setThumbUp(this.boulderId);
    } else if (newAscent.thumb === 'thumb-down') {
      await this.qualityRatingService.setThumbDown(this.boulderId);
    }
    if (this.boulder.isPrivate && this.boulder.author === this.authService.getCurrentUsername()) {
      await this.boulderService.setBoulderToPublic(this.boulderId);
    }
  }
}
